import React, { useEffect, useState, useRef, useContext } from "react";
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DropDownPicker from 'react-native-dropdown-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GradientButton from './GradientButton';
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import PhoneInput, { isValidNumber } from "react-native-phone-number-input";
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context";
import * as AppStorage from '../context/storage'
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR } from "../assets/styles";
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';
import { Asset } from 'expo-asset';
import { get, isEmpty, range, map, isNumber, forEach, noop } from 'lodash';
import { Feather, FontAwesome } from "@expo/vector-icons";
import { forModalPresentationIOS } from "@react-navigation/stack/lib/typescript/src/TransitionConfigs/CardStyleInterpolators";
import * as RootNavigation from '../RootNavigation';

const DatePicker = () => {
  const [isMonthPickerVisible, setIsMonthPickerVisible] = useState(false);
  const [isDayPickerVisible, setIsDayPickerVisible] = useState(false);
  const [isYearPickerVisible, setIsYearPickerVisible] = useState(false);
  const [birthdate_valid, set_birthdate_valid] = useState(true);
  const [birthdate, setBirthdate] = useState(0)
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(1);
  const [year, setYear] = useState(2000);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(AppContext);

  const submitField = async () => {
    await setIsLoading(true)
    const result = await updateUserProfile(dispatch, { birthdate: birthdate })
    await setIsLoading(false);
    RootNavigation.navigate('gender')
  }

  const getLoadingIndicator = () => {
    return (
      <ActivityIndicator size={40} color={PRIMARY_COLOR} />
    )
  }

  const disabled = () => {
    const unix = getUnix(year, month, day)
    return !(unix <= getDateXYearsAgo(18) && unix >= getDateXYearsAgo(120))
  }

  const goBack = async () => {
    await AppStorage.deleteItemAsync('secure_token')
    RootNavigation.navigate('Login')
  }

  const getUnix = (year, month, day) => {
    const date = new Date(year, month, day)
    return date.getTime() / 1000
  }

  const change_birthdate = (() => {
    const date = new Date(year, month, day)
    setBirthdate(date.getTime() / 1000)
  })

  const getDateXYearsAgo = (years) => {
    let yearsAgo = new Date();
    yearsAgo.setFullYear(yearsAgo.getFullYear() - years)
    return yearsAgo.getTime() / 1000
  }

  const closeAllPickers = () => {
    setIsMonthPickerVisible(false)
    setIsDayPickerVisible(false)
    setIsYearPickerVisible(false)
  }
  const months = {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December"
  }


  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate()
  }

  return (
    <TouchableWithoutFeedback onPress={() => closeAllPickers()}>
      <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <Text style={styles.title}>What is your birthdate?</Text>
        <View style={{ flexDirection: 'row', height: 40, justifyContent: 'center', alignItems: 'center', width: DIMENSION_WIDTH }}>
          <DropDownPicker
            open={isMonthPickerVisible}
            value={month}
            zIndex={3000}
            style={{ borderColor: 'transparent' }}
            dropDownContainerStyle={{ borderColor: 'transparent' }}
            containerStyle={[{ height: 40, maxWidth: 140, }]}
            labelStyle={{ fontSize: 22, textAlign: 'center' }}
            listItemLabelStyle={{ fontSize: 22, textAlign: 'center', paddingVertical: 10 }}
            selectedItemLabelStyle={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={false}
            placeholderStyle={{ display: 'none' }}
            items={map(range(0, 12), month => ({ label: months[month], value: month }))}
            setOpen={(open) => { closeAllPickers(); setIsMonthPickerVisible(open) }}
            setValue={(value) => { setMonth(value) }}
            theme="DARK"
            autoScroll={true}
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
          <View style={{ width: 100, alignItems: 'center' }}>
            <DropDownPicker
              open={isDayPickerVisible}
              value={day}
              zIndex={3000}
              style={{ borderColor: 'transparent' }}
              dropDownContainerStyle={{ borderColor: 'transparent' }}
              containerStyle={[{ height: 40, maxWidth: 70, }]}
              labelStyle={{ fontSize: 22, textAlign: 'center' }}
              listItemLabelStyle={{ fontSize: 22, textAlign: 'center', paddingVertical: 10  }}
              selectedItemLabelStyle={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
              showTickIcon={false}
              showArrowIcon={false}
              autoScroll={true}
              placeholderStyle={{ display: 'none' }}
              items={map(range(1, daysInMonth(month, year) + 1), day => ({ label: day, value: day }))}
              setOpen={(open) => { closeAllPickers(); setIsDayPickerVisible(open) }}
              setValue={(value) => { setDay(value) }}
              theme="DARK"
              listMode="SCROLLVIEW"
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
          </View>
          <DropDownPicker
            open={isYearPickerVisible}
            value={year}
            zIndex={3000}
            style={{ borderColor: 'transparent' }}
            dropDownContainerStyle={{ borderColor: 'transparent' }}
            containerStyle={[{ height: 40, maxWidth: 140, }]}
            labelStyle={{ fontSize: 22, textAlign: 'center' }}
            listItemLabelStyle={{ fontSize: 22, textAlign: 'center', paddingVertical: 10  }}
            selectedItemLabelStyle={{ fontSize: 22, textAlign: 'center', fontWeight: 'bold', color: SECONDARY_COLOR }}
            showTickIcon={false}
            showArrowIcon={false}
            autoScroll={true}
            placeholderStyle={{ display: 'none' }}
            items={map(range(new Date().getFullYear() - 100, new Date().getFullYear()), year => ({ label: year, value: year }))}
            setOpen={(open) => { closeAllPickers(); setIsYearPickerVisible(open) }}
            setValue={(value) => { setYear(value) }}
            theme="DARK"
            listMode="SCROLLVIEW"
            scrollViewProps={{
              nestedScrollEnabled: true,
            }}
          />
        </View>
        <View style={[styles.smallTextContainer, { zIndex: -1 }]}>
          <GradientButton
            style={{ marginVertical: 8, zIndex: 0 }}
            textStyle={{ fontSize: 20, color: TEXT_WHITE }}
            gradientBegin={PRIMARY_COLOR}
            gradientEnd={SECONDARY_COLOR}
            disabledGradientBegin={PRIMARY_COLOR_DISABLED}
            disabledGradientEnd={PRIMARY_COLOR_DISABLED}
            gradientDirection="diagonal"
            height={60}
            width={300}
            radius={15}
            impact
            impactStyle='Light'
            disabled={isLoading || disabled()}
            onPressAction={() => submitField()}
          >
            {isLoading ? getLoadingIndicator() : <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Continue'}</Text>}
          </GradientButton>
          <TouchableOpacity
            style={{ marginVertical: 8, zIndex: 0 }}
            disabled={isLoading}
            onPress={() => goBack()}
          >
            {isLoading ? getLoadingIndicator() : <Text style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center' }}>{'Go Back'}</Text>}
          </TouchableOpacity>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default DatePicker;
