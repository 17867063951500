import React, { useState, useEffect, useContext } from "react";
import { DataT, ProfileT } from "../types";
import { View, ImageBackground, Text } from "react-native";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { City, Filters, CardItem, PhotoSlider } from "../components";
import styles, { DIMENSION_WIDTH, PRIMARY_COLOR, SECONDARY_COLOR, TEXT_WHITE } from "../assets/styles";
import { AppContextProvider, AppContext, checkLogin, fetchUserProfile, fetchProfiles, fetchMatches, syncMyProfileData } from "../context/app-context";
import DEMO from "../assets/data/demo";
import {GradientButton} from '../components'
import * as RootNavigation from '../RootNavigation'
import { get, concat, isEmpty } from 'lodash'

const ErrorContainer = () => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    (async () => {

    })();
  }, []);

  const fetchData = async () => {
    setLoading(true)
    const result = await checkLogin()
    if(!result.logged_in) {
      RootNavigation.navigate('Login')
      setLoading(false)
      return
    }
    dispatch({
      type: "RECEIVE_LOGIN",
      payload: result
    })
    const response = await fetchUserProfile(dispatch)
    if(!get(response, 'profile.profile_complete', false)) {
    setLoading(false)
      return
    }
    await syncMyProfileData(dispatch)
    await fetchProfiles(dispatch)
    await fetchMatches(dispatch)
    await dispatch({
      type: "SET_PROFILE_INDEX",
      payload: 0
    })
    RootNavigation.navigate('Home')
    setLoading(false)
  }

  return (
    <View pointerEvents={'box-none'} style={[styles.bg, styles.horizontal, styles.darkBackground]} >
      <View pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground, {width: '100%'}]}>
      <Text style={[styles.attributesTextStyle, { textAlign: "center", marginVertical: 20 }]}>{`We failed to retrieve your user info`}</Text>
        <GradientButton
          style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
          gradientBegin={PRIMARY_COLOR}
          gradientEnd={SECONDARY_COLOR}
          gradientDirection="diagonal"
          height={60}
          width={300}
          impact
          impactStyle='Light'
          loading={loading}
          onPressAction={() => { fetchData() }}
        >
          <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500' }}>{`Try Again`}</Text>
        </GradientButton>
        </View>
    </View>
  );
};

export default ErrorContainer;
