import React, { useEffect, useState, useRef, useContext } from "react";
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform } from "react-native";
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack";
import * as SecureStore from 'expo-secure-store';
import { useNavigation } from "@react-navigation/native";
import { AppContext, updateUserProfile, fetchProfiles, fetchMatches, checkLogin } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, TEXT_WHITE, BACKGROUND_COLOR, PRIMARY_RED, SECONDARY_RED } from "../assets/styles";
import { get, isEmpty, has } from 'lodash';
import { PhotoPicker, DatePicker, GenderPicker, NamePicker, GradientButton } from "../components";
import * as RootNavigation from '../RootNavigation';

const Stack = createStackNavigator();

const CreateProfileContainer = () => {
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  let user_profile_info = {}

  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    (async () => {
      const result = await checkLogin()
      if(!result.logged_in) {
        RootNavigation.navigate('Login')
      }
    })();
  }, []);

  /*   const submitField = async (field) => {
      await setIsLoading(true)
      if (field == 'photos') {
        await fetchProfiles(dispatch)
        await fetchMatches(dispatch)
        await updateUserProfile(dispatch, { profile_complete: true })
        RootNavigation.replace('Home')
      }
      else if (!isEmpty(user_profile_info)) {
        const result = await updateUserProfile(dispatch, user_profile_info)
      }
      //handle error
      user_profile_info = {}
      await setIsLoading(false);
      if (nextField[field])
        RootNavigation.navigate(nextField[field])
    } */

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });


  return (
    <View style={[styles.bg, styles.horizontal, styles.darkBackground]}>
      <Stack.Navigator initialRouteName="first_name" screenOptions={{ cardStyleInterpolator: forFade, cardStyle: { backgroundColor: 'transparent' } }} >
        <Stack.Screen name="first_name" options={{ headerShown: false, animationEnabled: true }} component={NamePicker} />
        <Stack.Screen name="birthdate" options={{ headerShown: false, animationEnabled: true }} component={DatePicker} />
        <Stack.Screen name="gender" options={{ headerShown: false, animationEnabled: true }} component={GenderPicker} />
        <Stack.Screen name="photos" options={{ headerShown: false, animationEnabled: true }} component={PhotoPicker} />
      </Stack.Navigator>
    </View>
  );
};

export default CreateProfileContainer;
