import React, { useEffect, useState, useRef, useContext, useImperativeHandle } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE, TEXT_WHITE_60, TEXT_WHITE_40 } from "../assets/styles"
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set, capitalize, floor, clamp } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import RangeSlider, { Slider } from './RangeSlider'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { prompts } from "../assets/prompts"

const PreferencesEditor = (props : any) => {

  const [minAgeValue, setMinAgeValue] = useState(props?.profile?.min_age || 18);
  const [maxAgeValue, setMaxAgeValue] = useState(props?.profile?.max_age || 100);
  const [maxDistance, setMaxDistance] = useState(clamp(props?.profile?.max_distance || 1000, 5, 1000));
  const [value, setValue] = useState(0);

  return (
    <TouchableWithoutFeedback onPress={() => {}}>
      <View style={{width: '100%', maxWidth: vh(60), alignItems: 'center', paddingHorizontal: 10, marginVertical: 20}}>
        <View style={{width: '100%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
          <TouchableOpacity onPress={props.onCancel} hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Cancel'}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => props.onSave({max_age: maxAgeValue, min_age: minAgeValue, max_distance: maxDistance})}  hitSlop={{ top: 30, bottom: 30, left: 30, right: 30 }}>
            <Text style={styles.saveButtonText}>{'Save'}</Text>
          </TouchableOpacity>
        </View>
        <View style={[{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 100, paddingHorizontal: 10, marginVertical: 40} ]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Text style={[styles.attributesTextStyle, {marginBottom: 0}]}>{'Age Range'}</Text>
              <Text style={[styles.attributesTextStyle, {marginBottom: 0}]}>{`${minAgeValue}-${maxAgeValue}`}</Text>
            </View>
            <RangeSlider min={18} max={100}
              fromValueOnChange={value => setMinAgeValue(value)}
              toValueOnChange={value => setMaxAgeValue(value)}
              initialFromValue={props?.profile?.min_age}
              initialToValue={props?.profile?.max_age}
              showRangeLabels={false}
              showValueLabels={false}
              styleSize={'small'}
            />
        </View>
        <View style={[{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  width: '100%', height: 100, paddingHorizontal: 10, marginVertical: 40} ]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Text style={[styles.attributesTextStyle, {marginBottom: 0}]}>{'Max Distance'}</Text>
              <Text style={[styles.attributesTextStyle, {marginBottom: 0}]}>{`${maxDistance}${maxDistance === 1000 ? '+' : ''} mi`}</Text>
            </View>
            <Slider min={5} max={1000}
              valueOnChange={value => setMaxDistance(value)}
              initialValue={clamp(props?.profile?.max_distance || 1000, 5, 1000)}
              showRangeLabels={false}
              showValueLabels={false}
              styleSize={'small'}
            />
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default PreferencesEditor;

