const config = {
    API_URL: 'https://api.especiallyforsingles.com',
    IMAGES_URL: 'https://images.especiallyforsingles.com',
    FB_APP_ID: '587084592397778',
    VAPID_PUBLIC_KEY: 'BDH1Wey8sIzqss052JxsWdUBiWO1QGSMN0MW730y5yFIlqKA7UNBOy2MpAmZsV5mOcDEWf6wurQdDJPcr3UkF-g',
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyD2xKpLdO0YnvZoE36gmaNIlwHnYa1AA1Y",
        authDomain: "especially-for-singles.firebaseapp.com",
        projectId: "especially-for-singles",
        storageBucket: "especially-for-singles.appspot.com",
        messagingSenderId: "388565250021",
        appId: "1:388565250021:web:0002eb7012c66d211bc90c",
        measurementId: "G-HTERZ62KRP"
    }
};


export default config;