import React, { useEffect, useState, useRef, useContext } from "react";
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Image, Linking, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView } from "react-native";
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, GRAY, WHITE, PRIMARY_BLUE, SECONDARY_BLUE, PRIMARY_ORANGE, SECONDARY_ORANGE, PRIMARY_TEAL, SECONDARY_TEAL } from "../assets/styles";
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import { get, isEmpty, range, map, isNumber, forEach, noop } from 'lodash';
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import PhotoPicker from './PhotoPicker'
import GradientButton from './GradientButton'
import Icon from './Icon'
import * as RootNavigation from '../RootNavigation'
import ConfirmationModal from "../modals/ConfirmationModal"
import PreferencesEditor from './PreferencesEditor'

const ProfileMain = () => {
  const [state, dispatch] = useContext(AppContext)
  let profile = state.user_profile
  let image = get(profile, 'photos[0]')
  const goBack = async () => {
    
    RootNavigation.navigate('Login')
  }

  const saveChanges = async (profileChanges) => {
    await updateUserProfile(dispatch, profileChanges)
    await fetchProfiles(dispatch, true)
    await dispatch({
      type: "SET_PROFILE_INDEX",
      payload: 0
    })
    hideModal()
  }

  const openPreferencesModal = () => {
    console.log('yo:', profile)
    ConfirmationModal.showContext(
        <View style={[styles.darkBackground, { width: vw(100), height: vh(100), maxWidth: vh(60), padding: 20, borderRadius: 8, alignItems: 'center', justifyContent: 'flex-start'}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <PreferencesEditor onSave={(profileChanges) => {saveChanges(profileChanges)}} onCancel={hideModal} profile={profile}/>
          </SafeAreaView>
        </View>
    )
  }

  const hideModal = () => {
    ConfirmationModal.hideContext()
  }

  return (
    <View style={styles.containerHome}>
      <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 10, flex: 1, width: '100%'}}>
          <TouchableOpacity
            style={[{}]}
            onPress={() => {RootNavigation.navigate('ProfileEdit')}}
          >
            <Image
                source={typeof image == "string" ? {uri: `${config.IMAGES_URL}/${image}`}: image}
                style={{borderRadius: 100, width: 200, height: 200, resizeMode: 'cover'}}
            />
          </TouchableOpacity>
          <Text style={[styles.bigTitle, {marginBottom: 0, marginTop: 10, textAlign: 'center'}]}>{`${profile?.first_name}`}</Text>
          <View style={{flex: 1, width: '100%'}}>
            <TouchableOpacity onPress={() => {RootNavigation.navigate('ProfileEdit')}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_COLOR}
                gradientEnd={SECONDARY_COLOR}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {RootNavigation.navigate('ProfileEdit')}}
              ><Entypo name="pencil" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></Entypo></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500', lineHeight: 60 }}>{'  Edit Profile'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {openPreferencesModal()}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_BLUE}
                gradientEnd={SECONDARY_BLUE}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {openPreferencesModal()}}
              ><FontAwesome name="sliders" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></FontAwesome></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{'  Preferences'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {RootNavigation.navigate('ProfileAccount')}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_ORANGE}
                gradientEnd={SECONDARY_ORANGE}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {RootNavigation.navigate('ProfileAccount')}}
              ><FontAwesome name="gear" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></FontAwesome></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{'  Account'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => {Linking.openURL('https://forms.gle/ezHApkwFu5zn2V4a9')}} style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%', marginVertical: 20, paddingHorizontal: 10}}>
              <GradientButton
                style={{ marginVertical: 0, marginHorizontal: 10, alignItems: 'center', justifyContent: 'center' }}
                gradientBegin={PRIMARY_TEAL}
                gradientEnd={SECONDARY_TEAL}
                gradientDirection="diagonal"
                height={44}
                width={44}
                radius={22}
                impact
                impactStyle='Light'
                onPressAction={() => {Linking.openURL('https://forms.gle/ezHApkwFu5zn2V4a9')}}
              ><Entypo name="help" size={24} color={TEXT_WHITE} style={{lineHeight: 44}}></Entypo></GradientButton>
              <View style={{justifyContent: 'center', flex: 1}}><Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500' }}>{'  Help'}</Text></View>
              <Icon
                name="chevron-forward"
                size={30}
                color={TEXT_WHITE}
              />
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </View>
  )
}

export default ProfileMain;
