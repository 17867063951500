import React, { useEffect, useState, useRef, useContext } from "react"
import config from "../config.js"
import { ActivityIndicator, View, Text, TextInput, Image, Button, TouchableOpacity, TouchableWithoutFeedback, Platform, ScrollView, SafeAreaView, KeyboardAvoidingView, Keyboard } from "react-native"
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, updateUserProfile, uploadPhoto, removePhoto } from "../context/app-context"
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, WHITE, TEXT_WHITE_60, TEXT_WHITE_40 } from "../assets/styles"
import { get, isEmpty, range, map, isNumber, forEach, noop, assign, union, set, capitalize, floor, startCase, has } from 'lodash'
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import DropDownPicker from 'react-native-dropdown-picker'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import PhotoPicker from './PhotoPicker'
import Icon from './Icon'
import PromptEditor from './PromptEditor'
import AttributeEditor, {attributeOptions, attributeLabels} from './AttributeEditor'
import * as RootNavigation from '../RootNavigation'
import { validatePathConfig } from "@react-navigation/native"
import ConfirmationModal from "../modals/ConfirmationModal"
import { prompts } from "../assets/prompts"

const ProfileEdit = () => {
  const [state, dispatch] = useContext(AppContext)
  const profile = state.user_profile
  const image = get(profile, 'photos[0]')
  const [pickerVisible, setPickerVisible] = useState(false)
  const clickRef = React.useRef()
  const [items, setItems] = useState(map(prompts, prompt => ({ label: prompt, value: prompt })));

  const [about, setAbout] = useState(get(profile, 'about', []))

  useEffect(() => {
    (async () => {
      setAbout(get(profile, 'about', []))
    })();
  }, [profile]);

  const showPromptAnswerEditModal = (about, index) => {
    console.log(about)
    const prompt = get(about, `[${index}].prompt`, prompts[index])
    const promptAnswer = get(about, `[${index}].answer`, '')

    ConfirmationModal.showContext(
      <TouchableWithoutFeedback onPress={() => callPressableFunc()}>
        <View style={[styles.darkBackground, { width: vw(100), maxWidth: vh(60), height: Platform.OS === 'android' ? undefined : vh(100), alignItems: 'center', justifyContent: 'flex-start'}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <PromptEditor prompt={prompt} promptAnswer={promptAnswer} index={index} onSave={savePromptConfirmationModal} onCancel={cancelConfirmationModal} ref={clickRef}/>
          </SafeAreaView>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  const showAttributeEditModal = (attribute, attributeValue) => {
    ConfirmationModal.showContext(
        <View style={[styles.darkBackground, { width: vw(100), maxWidth: vh(60), height: Platform.OS === 'android' ? undefined : vh(100), alignItems: 'center', justifyContent: 'flex-start'}]}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <AttributeEditor attribute={attribute} attributeValue={attributeValue} onSave={saveAttributeConfirmationModal} onCancel={cancelConfirmationModal} ref={clickRef}/>
          </SafeAreaView>
        </View>
    )
  }

  const saveAttributeConfirmationModal = async (attribute, attributeValue) => {
    console.log('saving!', {[attribute]: attributeValue})
    const result = await updateUserProfile(dispatch, { [attribute]: attributeValue })
    ConfirmationModal.hideContext()
  }

  const savePromptConfirmationModal = async (prompt, promptAnswer, index) => {
    console.log('saving!', about)
    const newAbout = assign([], about, {[index]: assign(get(about, `[${index}]`), {answer: promptAnswer, prompt: prompt})})
    const result = await updateUserProfile(dispatch, { about: newAbout })
    ConfirmationModal.hideContext()
  }

  const cancelConfirmationModal = () => {
    ConfirmationModal.hideContext()
  }

  const goBack = async () => {
    RootNavigation.navigate('Login')
  }

  const getPromptComponent = (index : number) => {
    const prompt = get(about, `[${index}].prompt`, prompts[index])
    const promptAnswer = get(about, `[${index}].answer`, '')

    return (
      <TouchableOpacity key={`prompt-${index}`} onPress={() => showPromptAnswerEditModal(about, index)}>
        <View style={[styles.promptContainer, {marginVertical: 10, padding: 10}]}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 20}}>
            <Text style={[styles.attributesTextStyle, {lineHeight: 16}]}>{prompt}</Text>
            <Entypo name="pencil" size={14} color={TEXT_WHITE} style={{alignSelf: 'flex-start'}}></Entypo>
          </View>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Text style={[styles.aboutCardItem, {color: promptAnswer ? TEXT_WHITE : TEXT_WHITE_40}]}>{promptAnswer ? promptAnswer : prompt}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  const getAttributeValue = (attribute : string) => {
    const attributeValue = get(profile, attribute)
    if(attribute == 'height' && isNumber(attributeValue)) {
      const feet = floor(attributeValue / 12)
      const inches = attributeValue % 12
      return `${feet}'${inches}"`
    }

    const pretty = has(attributeLabels, attributeValue) ? attributeLabels[attributeValue] : attributeValue
    return pretty ? capitalize(pretty) : 'Prefer not to say'
  }

  const getAttribute = (attribute : string) => {
    const attributeLabel = startCase(has(attributeLabels, attribute) ? attributeLabels[attribute] : attribute)
    const attributeValue = getAttributeValue(attribute)
    return (
      <TouchableOpacity key={attribute} onPress={() => showAttributeEditModal(attribute, profile[attribute])}>
        <View style={[styles.promptContainer, {marginVertical: 10, padding: 10}]}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Text style={[styles.attributesTextStyle, {lineHeight: 16}]}>{attributeLabel}</Text>
            <Entypo name="pencil" size={14} color={TEXT_WHITE} style={{alignSelf: 'flex-start'}}></Entypo>
          </View>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
            <Text style={[styles.aboutCardItem, {color: TEXT_WHITE_40, marginBottom: 0}]}>{attributeValue}</Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  const callPressableFunc = () => {
    console.log('what the?')
    clickRef?.current?.closeAllPickers()
  };

  return (
    <TouchableWithoutFeedback>
      
      <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <View style={[styles.bg, styles.containerHome, styles.horizontal, {flex: 1, paddingHorizontal: 20, marginTop: 20}]}> 
          <KeyboardAwareScrollView style={[styles.containerProfile, {marginTop: 40}]} showsVerticalScrollIndicator={false} keyboardOpeningTime={0} enableResetScrollToCoords={false}>
            <View>
              <PhotoPicker showContinue={false}></PhotoPicker>
              {map(range(0, 4), index => (getPromptComponent(index)))}  
              {getAttribute('company')}
              {getAttribute('title')}
              {getAttribute('school')}
              {getAttribute('mission')}
              {getAttribute('hometown')}
              {getAttribute('height')}
              
              {getAttribute('activity_level')}
              {getAttribute('marriage_status')}
              {getAttribute('current_kids')}
              {getAttribute('future_kids')}
              {getAttribute('education')}
              {getAttribute('politics')}
              {getAttribute('temple_status')}
              {getAttribute('pets')}
            </View>
            </KeyboardAwareScrollView>
        </View>
        <TouchableOpacity style={[{position: 'absolute', justifyContent: 'center', alignItems: 'center', padding: 10}, styles.topIconLeft]} onPress={() => { RootNavigation.navigate('ProfileMain')  }}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>
      </SafeAreaView>
    </TouchableWithoutFeedback>
  )
}

export default ProfileEdit;

