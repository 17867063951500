import React, { useEffect, useState, useRef, useContext  } from "react";
import config from "../config.js";
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  FlatList,
  Button,
  TouchableWithoutFeedback,
  Platform,
  SafeAreaView
} from "react-native";
import WebModal from "modal-enhanced-react-native-web";
import Modal from "react-native-modal";
import { CardItem, Icon, NamePicker, PhotoSlider, ProfileView, GradientButton } from "../components";
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import { first } from 'lodash';
import DEMO from "../assets/data/demo";
import { Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { AppContext, uploadPhoto, removePhoto, profileAction} from "../context/app-context";
import { LinearGradient } from 'expo-linear-gradient';
import styles, { GRAY, DARK_GRAY, DIMENSION_HEIGHT, DIMENSION_WIDTH, INPUT_BACKGROUND_COLOR, PRIMARY_COLOR, SECONDARY_COLOR, WHITE, TEXT_WHITE, SECONDARY_RED } from "../assets/styles";
import { createStackNavigator } from "@react-navigation/stack";
import { get, range, map, noop, floor, round, clamp, last, isEmpty, filter } from 'lodash';
import { BlurView } from 'expo-blur';
import * as RootNavigation from '../RootNavigation'
import ProfileModal from '../modals/ProfileModal'


const Stack = createStackNavigator();

const Messages = () => {
  const [state, dispatch] = useContext(AppContext)

  const unfilteredMatches = get(state, `matches`, [])
  const matches = filter(unfilteredMatches, {is_liked: true, likes_me: true})

  useEffect(() => {
    (async () => {
      console.log('messages mounted!')
    })();
  }, []);

  const showProfileModal = (profile) => {
    ProfileModal.showContext(
      <View style={{width: vw(100), height: vh(100), maxWidth: vh(60)}}>
        <ProfileView profile={profile} width={vw(100)} height={vh(100)}>
          {getActions(profile)}
        </ProfileView>
        <TouchableOpacity style={[{position: 'absolute'}, styles.topIconLeft, {top: 50}]} onPress={() => {hideProfileModal()}}>
          <Icon
            name="chevron-back"
            size={40}
            color={WHITE}
          />
        </TouchableOpacity>  
      </View>
    )
  }

  const hideProfileModal = () => {
    ProfileModal.hideContext()
  }

  const doAction = async (action: string, profile) => {
    await profileAction(dispatch, action, profile?.id)
    hideProfileModal()
}

  const getActions = (profile) => {
    return (
    <View pointerEvents={'box-none'} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', zIndex: 2, width: '100%', height: '100%', paddingBottom: 20 }}>
      <GradientButton
        style={{ marginVertical: 0, marginHorizontal: 20 }}
        gradientBegin={GRAY}
        gradientEnd={DARK_GRAY}
        gradientDirection="diagonal"
        height={60}
        width={60}
        impact
        impactStyle='Light'
        onPressAction={() => doAction('dislike', profile)}
      ><Ionicons name="heart-dislike-sharp" size={40} color={TEXT_WHITE} /></GradientButton>
    </View>
    )
  }
  

  const conversationItem = (match) => {
    const image = get(match, `photos[0]`)
    const first_name = match?.first_name
    const messages = get(match, 'messages', [])
    const last_message_obj = first(messages)
    const last_message = last_message_obj?.message
    const unread = (match?.messages?.length === 0 || get(match, 'messages[0].to_profile_id') !== match?.id)
    return (
    <TouchableOpacity
      style={[{height: 80, flexDirection: 'row', alignItems: 'center', margin: 10, overflow: 'hidden', width: '100%'}]}
      key={match?.id}
      onPress={() => {if(match?.id) RootNavigation.navigate('Conversation', {id: match?.id})  }}
    >
      <TouchableOpacity
        style={[{}]}
        key={match?.id}
        onPress={() => {showProfileModal(match)}}
      >
        <Image
            source={typeof image == "string" ? {uri: `${config.IMAGES_URL}/${image}`}: image}
            style={{borderRadius: 90, width: 80, height: 80, resizeMode: 'cover'}}
        />
      </TouchableOpacity>
      <View style={[{borderRadius: 8, height: '100%', width: '100%', flex: 10, alignItems: 'flex-start', justifyContent: 'center'}]}>  
        <Text style={[styles.nameStyleSmall, {marginLeft: 10, marginBottom: 5}]}>{first_name}</Text>
        <Text numberOfLines={1} style={[styles.messageStyleSmall, {marginLeft: 10, marginBottom: 5, marginRight: 40}]} >{last_message ? last_message : 'Send them a message!'}</Text>
      </View>
      {unread && <View style={{flex: 1, maxWidth: 14, width: 14, height: 14, backgroundColor: SECONDARY_RED, marginRight: 20, borderRadius: 14}}></View>}
    </TouchableOpacity>
    )
  }

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });

  const Likes = () => {
    return (
      <View pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <Text style={[styles.title, {marginTop: 50}]}>{'Likes'}</Text>
        <FlatList
          numColumns={2}
          data={matches}
          keyExtractor={(item , index) => (index.toString())}
          renderItem={({item}) => (matchCard(item))}
        />
      </View>
    )
  }

  return (
    <View pointerEvents={'box-none'} style={[styles.bg, styles.horizontal, styles.darkBackground]} >
      <SafeAreaView pointerEvents={'box-none'} style={[styles.container, styles.horizontal, styles.darkBackground, {width: '100%'}]}>
        <Text style={[styles.pageTitle, {padding: 10, minHeight: 48}]}>{`MESSAGES`}</Text>
        <FlatList
          numColumns={1}
          data={matches}
          keyExtractor={(item , index) => (index.toString())}
          renderItem={({item}) => (conversationItem(item))}
          style={{width: '100%'}}
          contentContainerStyle={{width: '100%'}}
        />
          {isEmpty(matches) && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 4, position: 'absolute', width: '100%', height: '100%', paddingHorizontal: 50 }}>
          <Text style={[styles.attributesTextStyle, { textAlign: "center", marginVertical: 20 }]}>{`No messages quite yet, try liking some people to get matches!`}</Text>
          <GradientButton
            style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
            gradientBegin={PRIMARY_COLOR}
            gradientEnd={SECONDARY_COLOR}
            gradientDirection="diagonal"
            height={60}
            width={300}
            impact
            impactStyle='Light'
            onPressAction={() => { RootNavigation.navigate('Explore') }}
          >
            <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500' }}>{`See People`}</Text>
          </GradientButton>
        </View>}
      </SafeAreaView>
    </View>
  )
}

export default Messages;
