import { StackActions } from '@react-navigation/routers';
import { createNavigationContainerRef } from '@react-navigation/native';
import * as React from 'react';

export const navigationRef = createNavigationContainerRef()

export const navigate = (name:any, params?:any) => {
  console.log('trying to navigate', name, params)
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  } else {
    console.log('not mounted')
  }
}

export const navigateRoot = () => {
  if(navigationRef.isReady()) {
    navigationRef.resetRoot()
  } else {
    console.log('not mouted')
  }
}

export const goBack = () => {
  console.log('trying to go back')
  if (!navigationRef.isReady()) return false
  if(!navigationRef.canGoBack()) return false
  navigationRef.goBack()
  return true
  
}

export const replace = (name, params) => {
  console.log('trying to replace', name, params)
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.replace(name))
  } else {
    console.log('not mounted')
  }
}
