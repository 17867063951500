import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
  useWindowDimensions,
  Animated,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import config from "../config.js";
import { Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import GradientButton from './GradientButton';
import { clamp, get, round, floor, isEmpty, invoke } from 'lodash';
import * as Location from 'expo-location'
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import { LinearGradient } from 'expo-linear-gradient';
import * as RootNavigation from '../RootNavigation';

import styles, {
  DISLIKE_ACTIONS,
  FLASH_ACTIONS,
  LIKE_ACTIONS,
  STAR_ACTIONS,
  WHITE,
  DARK_GRAY,
  DIMENSION_WIDTH,
  DIMENSION_HEIGHT,
  TEXT_WHITE_60,
  TEXT_WHITE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  PRIMARY_RED,
  SECONDARY_RED,
  BACKGROUND_COLOR,
  PRIMARY_GREEN,
  SECONDARY_GREEN,
  PRIMARY_COLOR_DISABLED,
  GRAY
} from "../assets/styles";
import { PhotoSliderT } from '../types';
import { AppContext, fetchProfiles, incrementProfileIndex, profileAction, profileRewind, syncMyProfileData, fetchMatches } from '../context/app-context';
import ProfileView from './ProfileView.tsx'
import Toast from 'react-native-toast-message'

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const PhotoSlider = () => {
  const [state, dispatch] = useContext(AppContext);
  const width = useWindowDimensions().width;
  const height = useWindowDimensions().height;
  const [hasLocationPermissions, setHasLocationPermissions] = useState(false);

  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fadeColor, setFadeColor] = useState(SECONDARY_COLOR);
  const [fadeAnimation, setFadeAnimation] = useState(new Animated.Value(0));

  const photoScrollRef = React.useRef({});
  const mainScrollRef = React.useRef({});

  const profile = state.profile
  const nextProfile = state.nextProfile

  const fadeIn = async (cb: any) => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true
    }).start(cb);
  };

  const fadeOut = async (cb?: any) => {
    Animated.timing(fadeAnimation, {
      toValue: 0,
      duration: 150,
      useNativeDriver: true
    }).start(cb);
  };

  const change = ({ nativeEvent }: any) => {
    const slide = round(
      nativeEvent.contentOffset.x / nativeEvent.layoutMeasurement.width,
    );
    clamp(slide, 0, get(profile, 'images.length', 0));
    if (slide !== active) {
      setActive(slide);
    }
  };

  useEffect(() => {
    (async () => {
      nextProfile?.photos?.forEach(photo => Image.prefetch(`${config.IMAGES_URL}/${photo}`))
    })();
  }, [nextProfile]);

  useEffect(() => {
    (async () => {
      nextProfile?.photos?.forEach(photo => Image.prefetch(`${config.IMAGES_URL}/${photo}`))
    })();
  }, [nextProfile]);

  useEffect(() => {
    (async () => {
      console.log('profile changed!', state.profile, profile, state.profiles)
    })();
  }, [profile]);

  const next = (action: string) => {
    setIsLoading(true)
    action == 'like' ? setFadeColor(SECONDARY_COLOR) : setFadeColor(DARK_GRAY)
    fadeIn(async () => {
      // @ts-ignore: Object is possibly 'null'.
      invoke(photoScrollRef, 'current.scrollTo', { x: 0, y: 0, animated: false })
      invoke(mainScrollRef, 'current.scrollTo', { x: 0, y: 0, animated: false })
      await profileAction(dispatch, action, profile.id)
      if(state.profile_index % 18 == 0) await fetchProfiles(dispatch)
      await incrementProfileIndex(dispatch)
      fadeOut()
      setIsLoading(false)
    })
  }

  const rewind = async () => {
    setIsLoading(true)
    await profileRewind(dispatch)
    setIsLoading(false)
  }

  let photos = get(profile, 'photos', [])
  if (isEmpty(photos)) photos = []

  useEffect(() => {
    (async () => {
      const { status } = await Location.getForegroundPermissionsAsync()
      status === 'granted' ? setHasLocationPermissions(true) : setHasLocationPermissions(false)
    })();
  }, []);

  
  const promptLocationPermission = async () => {
    let { status, canAskAgain } = await Location.requestForegroundPermissionsAsync();
    console.log(status, canAskAgain)
    if(status === 'denied' && !canAskAgain) {
      console.log('cant ask again' )
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'Go to your browser/device settings to enable location'
      })
    }
    console.log('promptLocationPermission', status)
    status === 'granted' ? setHasLocationPermissions(true) : setHasLocationPermissions(false)
    if (status !== 'granted') {    
      console.log('Permission to access location was denied');
    }

    await syncMyProfileData(dispatch)
    await fetchProfiles(dispatch)
    await fetchMatches(dispatch)
  }

  if(!hasLocationPermissions) {
    return (
      <View style={{ flex: 1}}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 4, position: 'absolute', width: '100%', height: '100%', paddingHorizontal: 50 }}>
          <SafeAreaView pointerEvents={'box-none'} style={{width: '100%', alignItems: 'center'}}>
            <Text style={[styles.reportStyle, { fontSize: 20, color: TEXT_WHITE_60, textAlign: 'center' }]}>{'We need permission to access your location to show you matches in your area'}</Text>
            <View style={{width: '80%',  flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
              <GradientButton
                style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
                gradientBegin={PRIMARY_COLOR}
                gradientEnd={SECONDARY_COLOR}
                gradientDirection="diagonal"
                height={60}
                width={300}
                impact
                impactStyle='Light'
                onPressAction={() => { promptLocationPermission()}}
              >
                <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500' }}>{`Ok`}</Text>
              </GradientButton>
            </View>
          </SafeAreaView>
        </View>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      {fadeAnimation && <Animated.View pointerEvents={'box-none'}
        style={{ zIndex: 3, opacity: fadeAnimation, width: '100%', height: '100%', backgroundColor: fadeColor, position: 'absolute' }}
      >
      </Animated.View>}
      {isEmpty(profile) && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', zIndex: 4, position: 'absolute', width: '100%', height: '100%', paddingHorizontal: 50 }}>
        <Text style={[styles.attributesTextStyle, { textAlign: "center", marginVertical: 20 }]}>{`You've seen all the people that match your filters, expand them to see more!`}</Text>
        <GradientButton
          style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
          gradientBegin={PRIMARY_COLOR}
          gradientEnd={SECONDARY_COLOR}
          gradientDirection="diagonal"
          height={60}
          width={300}
          impact
          impactStyle='Light'
          onPressAction={() => { RootNavigation.navigate('Profile') }}
        >
          <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500' }}>{`Go To Preferences`}</Text>
        </GradientButton>
        <GradientButton
          style={{ marginVertical: 0, marginLeft: 0, justifyContent: 'center', alignItems: 'center', marginVertical: 20 }}
          gradientBegin={PRIMARY_COLOR}
          gradientEnd={SECONDARY_COLOR}
          gradientDirection="diagonal"
          height={60}
          width={300}
          impact
          loading={isLoading}
          impactStyle='Light'
          onPressAction={() => {rewind()}}
        >
          <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, textAlign: 'center', fontWeight: '500' }}>{`Rewind Skipped Profiles`}</Text>
        </GradientButton>
      </View>}
      <ProfileView profile={profile} width={vw(100)} height={vh(100)}>
        <View pointerEvents={'box-none'} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', zIndex: 2, width: '100%', height: '100%', paddingBottom: 20 }}>
          <GradientButton
            style={{ marginVertical: 0, marginHorizontal: 20 }}
            gradientBegin={GRAY}
            gradientEnd={DARK_GRAY}
            gradientDirection="diagonal"
            height={60}
            width={60}
            impact
            impactStyle='Light'
            disabled={isLoading}
            onPressAction={() => next('dislike')}
          ><Ionicons name="heart-dislike-sharp" size={40} color={TEXT_WHITE} /></GradientButton>
          <GradientButton
            style={{ marginVertical: 0, marginHorizontal: 20 }}
            gradientBegin={PRIMARY_COLOR}
            gradientEnd={SECONDARY_COLOR}
            gradientDirection="diagonal"
            height={60}
            width={60}
            impact
            impactStyle='Light'
            disabled={isLoading}
            onPressAction={() => next('like')}
          ><Ionicons name="heart-sharp" size={40} color={TEXT_WHITE} /></GradientButton>
        </View>
      </ProfileView>
    </View>
  );
}

export default PhotoSlider;