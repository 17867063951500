import React, { useContext } from "react";
import {
  ScrollView,
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  Image
} from "react-native";
import config from "../config.js";
import * as AppStorage from '../context/storage'
import * as Notifications from 'expo-notifications';
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from 'expo-secure-store';
import { first, get } from 'lodash';
import { Icon, PhotoPicker, ProfileItem, ProfileMain, ProfileEdit, ProfileAccount } from "../components";
import DEMO from "../assets/data/demo";
import styles, { INPUT_BACKGROUND_COLOR, WHITE } from "../assets/styles";
import { ProfileDataT } from "../types";
import * as RootNavigation from '../RootNavigation';
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack";
import { AppContext, profileAction } from '../context/app-context';

const Stack = createStackNavigator();

const Profile = () => {

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });

  return (
    <View style={styles.containerHome}>
      <Stack.Navigator initialRouteName="ProfileMain" screenOptions={{ cardStyleInterpolator: forFade, cardStyle: { backgroundColor: 'transparent' } }} >
        <Stack.Screen name="ProfileMain" options={{ headerShown: false, animationEnabled: true }} component={ProfileMain} />
        <Stack.Screen name="ProfileEdit" options={{ headerShown: false, animationEnabled: true }} component={ProfileEdit} />
        <Stack.Screen name="ProfileAccount" options={{ headerShown: false, animationEnabled: true }} component={ProfileAccount} />
      </Stack.Navigator>
        {/* <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
          <TouchableOpacity
            style={[{}]}
            onPress={() => {RootNavigation.navigate('EditProfile')}}
          >
            <Image
                source={typeof image == "string" ? {uri: `${config.IMAGES_URL}/${image}`}: image}
                style={{borderRadius: 90, width: 200, height: 200, resizeMode: 'cover'}}
            />
          </TouchableOpacity>
          <Text style={[styles.title, {marginBottom: 0, textAlign: 'center'}]}>{`${profile?.first_name}`}</Text>
        </View>

        <View style={[styles.container, { backgroundColor: INPUT_BACKGROUND_COLOR }]}>
          <Text style={styles.title}>{profile.first_name}</Text>
          <Text style={styles.title}>{profile.gender}</Text>
          <Text style={styles.title}>{profile.birthdate}</Text>
          <PhotoPicker showContinue={false}></PhotoPicker>
        </View> */}

        {/* <View style={styles.actionsProfile}>
          <TouchableOpacity style={styles.circledButton}>
            <Icon name="ellipsis-horizontal" size={20} color={WHITE} />
          </TouchableOpacity>

          <TouchableOpacity style={styles.roundedButton}>
            <Icon name="chatbubble" size={20} color={WHITE} />
            <Text style={styles.textButton}>Start chatting</Text>
          </TouchableOpacity>
        </View> */}
    </View>
  );
};

export default Profile;
