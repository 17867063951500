import React, { useEffect, useState, useRef, useContext } from "react";
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import * as FacebookWeb from 'expo-auth-session/providers/facebook';
import * as Facebook from 'expo-facebook';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth'
import * as AppleAuthentication from 'expo-apple-authentication'
import { LinearGradient } from 'expo-linear-gradient'
import { ResponseType } from 'expo-auth-session';
import config from "../config.js";
import { ActivityIndicator, View, Text, TextInput, Button, Linking, Image, Platform } from "react-native";
import { AntDesign, Entypo, Feather, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AppStorage from '../context/storage'
import { CardItem, Icon, NamePicker, PhotoSlider, ProfileView, TabBarLabel, GradientButton } from "../components";
import { useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import PhoneInput from "react-native-phone-number-input";
import { AppContextProvider, AppContext, fetchData, fetchUserProfile, fetchProfiles, profileComplete, fetchMatches } from "../context/app-context";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, PRIMARY_COLOR_DISABLED, DARK_GRAY, TEXT_WHITE, BLACK } from "../assets/styles";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { get } from 'lodash';
import * as RootNavigation from '../RootNavigation'
import Toast from 'react-native-toast-message'
import { v4 as uuid } from 'uuid'


import flags from '../assets/data/flags.json';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const phoneUtil = PhoneNumberUtil.getInstance();

WebBrowser.maybeCompleteAuthSession();

interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const LoginContainer = () => {
  const navigation = useNavigation();
  const [loginStep, setLoginStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [state, dispatch] = useContext(AppContext);
  //const [state, setState] = useState({});

  const [googleRequest, googleResponse, googlePromptAsync] = Google.useAuthRequest({
    expoClientId: '388565250021-gec8gj2t5b0k2d41cpuv23h3in7av6jj.apps.googleusercontent.com',
    iosClientId: '388565250021-0437a5sth1esff4msjbmn43s51jng2hq.apps.googleusercontent.com',
    androidClientId: '388565250021-6gdfj9epgve521dphkrhih040741cjcv.apps.googleusercontent.com',
    webClientId: '388565250021-ui47pq3vq8jn7nt69v509murrb78br0v.apps.googleusercontent.com',
  })

  const [fbRequest, fbResponse, fbPromptAsync] = FacebookWeb.useAuthRequest({
    clientId: '587084592397778',
    responseType: ResponseType.Token,
  });
  
  if(Platform.OS === 'android') {
    Facebook.initializeAsync({
      appId: '587084592397778',
    });
  }
  


  useEffect(() => {
    if (googleResponse?.type === 'success') {
      setIsLoading(true)
      const { authentication } = googleResponse;
      const token = get(authentication, 'accessToken')
      console.log('auth', authentication)
      
      if (!token) {
        clientError('auth ' + JSON.stringify(authentication))
        console.error('no token', authentication)
        setIsLoading(false)
        return
      } else {
        login(token, 'google')
      }
      
    }
    if (fbResponse?.type === 'success') {
      const { code } = fbResponse.params;
    }
    
  }, [googleResponse, fbResponse])


  const fbLogin = async () => {
    if(Platform.OS === 'android') {
      const { type, token, expirationDate, permissions, declinedPermissions } =
        await Facebook.logInWithReadPermissionsAsync({
          permissions: ['public_profile'],
        });
        clientError('fbLogin ' + 'type ' + 'token')  
      if (type === 'success') {
        setIsLoading(true)
        login(token, 'facebook')
      } else {
        // type === 'cancel'
      }
      return
    }
    const result = await fbPromptAsync();
    clientError('fbLogin ' + JSON.stringify(result))
    if (result?.type == 'success') {
      setIsLoading(true)
      const token = get(result, 'authentication.accessToken')
      console.log(token)
      if (!token) {
        console.log('no token', result)
        setIsLoading(false)
      }
      login(token, 'facebook')
    }
  }

  const googleLogin = async () => {
    const result = await googlePromptAsync();  
  }

  if(Platform.OS === 'web') {
    useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);
  }

  const appleLogin = async () => {
    console.log('apple login!')
    if(Platform.OS === 'web') {
      appleAuthHelpers.signIn({
        authOptions: {
          clientId: 'app.especiallyforsingles.com',
          redirectURI: 'https://app.especiallyforsingles.com',
          scope: 'email name',
          state: 'state',
          /** sha256 nonce before sending to apple to unify with native firebase behavior - https://github.com/invertase/react-native-apple-authentication/issues/28 */
          nonce: 'nonce',
          /** We have to usePopup since we need clientSide authentication */
          usePopup: true,
          responseType: "ALL",
        },
        onSuccess: (response) => {
          console.log(response)
          const token = get(response, 'authorization.code')
          if (!token) {
            console.log('no token', response)
            setIsLoading(false)
            return
          }
          login(token, 'apple-web')
        },
        onError: (error) => {
          console.error(error)
          clientError(JSON.stringify(error))
          setIsLoading(false)
        },
      });
    } else if (Platform.OS === 'ios') {
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
      });
      console.log(credential)
      const token = get(credential, 'authorizationCode')
      if (!token) {
        clientError(JSON.stringify(credential))
        console.log('no token', credential)
        setIsLoading(false)
        return
      }
      login(token, 'apple')
    }
  }

  const clientError = async (message) => {
    const response = await fetch(`${config.API_URL}/auth/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': 'esf-app'
      },
      body: JSON.stringify({
        message: message,
      })
    });
  }

  const login = async (token, type) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${config.API_URL}/auth/oauth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-csrf-token': 'esf-app'
        },
        body: JSON.stringify({
          type: type,
          token: token
        })
      });
      const json = await response.json();

      if (response.status == 200) {
        const token = await get(json, 'resources.token');
        await AppStorage.setItemAsync('secure_token', token);
        const response = await fetchUserProfile(dispatch)
        if(response?.error) {
          return
        }
        await dispatch({
          type: "RECEIVE_LOGIN",
          payload: {
            token: token,
            logged_in: true
          }
        })
        if (get(response, 'profile.profile_complete') === false) {
          await RootNavigation.navigate('CreateProfile')
          setIsLoading(false)
          return
        }

        await fetchProfiles(dispatch)
        await fetchMatches(dispatch)
        await dispatch({
          type: "SET_PROFILE_INDEX",
          payload: 0
        })
        await RootNavigation.replace('Home')
        setIsLoading(false)
      } else {
        console.error(response.status, response.statusText, json)
        setIsLoading(false)
      }


    } catch (err) {
      console.error('submitVerificationCode', err);
      setIsLoading(false)
    }
  }

  const getLoadingScreen = () => {
    return (
      <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <ActivityIndicator size={80} color={PRIMARY_COLOR} />
      </View>
    )
  }

  const getLoadingIndicator = () => {
    return (
      <ActivityIndicator size={40} color={PRIMARY_COLOR} />
    )
  }

  const rawNonce = uuid();
  const stateuuid = uuid();

  const getLoginScreen = () => {
    return (
      <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
        <Image
          source={require("../assets/text-logo.png")}
          style={{ width: 50, height: 50 }}
        />
        <Text style={[styles.bigTitle, { marginBottom: 200 }]}>Especially for Singles</Text>
        <View style={styles.smallTextContainer}>
          <Text style={[styles.centerText, styles.mediumText]}>{'By continuing, you agree to our '}
            <Text
              style={[styles.centerText, styles.mediumText, { textDecorationLine: 'underline', fontWeight: 'bold' }]}
              onPress={() => { Linking.openURL('https://especiallyforsingles.com/terms') }}
            >
              {'Terms of Service'}
            </Text>
            {'.  Learn how we process your data in our '}
            <Text
              style={[styles.centerText, styles.mediumText, { textDecorationLine: 'underline', fontWeight: 'bold' }]}
              onPress={() => { Linking.openURL('https://especiallyforsingles.com/privacy') }}
            >
              {'Privacy Policy'}
            </Text>
            {'.'}
          </Text>
          {isLoading ? getLoadingIndicator() : <View style={{alignItems: 'center', justifyContent: 'center'}}>
            <GradientButton
              style={{ marginVertical: 8, alignItems: 'center', justifyContent: 'center' }}
              textStyle={{ fontSize: 20, color: TEXT_WHITE }}
              gradientBegin={PRIMARY_COLOR}
              gradientEnd={SECONDARY_COLOR}
              disabledGradientBegin={PRIMARY_COLOR_DISABLED}
              disabledGradientEnd={PRIMARY_COLOR_DISABLED}
              gradientDirection="diagonal"
              height={56}
              width={300}
              radius={15}
              impact
              impactStyle='Light'
              disabled={isLoading}
              onPressAction={() => fbLogin()}
            >
              {isLoading ? getLoadingIndicator() : 
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Entypo name="facebook" size={20} color={TEXT_WHITE}></Entypo>
                <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500', lineHeight: 56 }}>{'  Continue with Facebook'}</Text>
              </View>
              }
            </GradientButton>

            <GradientButton
              style={{ marginVertical: 8, alignItems: 'center', justifyContent: 'center' }}
              textStyle={{ fontSize: 20, color: TEXT_WHITE }}
              gradientBegin={PRIMARY_COLOR}
              gradientEnd={SECONDARY_COLOR}
              disabledGradientBegin={PRIMARY_COLOR_DISABLED}
              disabledGradientEnd={PRIMARY_COLOR_DISABLED}
              gradientDirection="diagonal"
              height={56}
              width={300}
              radius={15}
              impact
              impactStyle='Light'
              disabled={isLoading}
              onPressAction={() => googleLogin()}
            >
              {isLoading ? getLoadingIndicator() : 
              <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <AntDesign name="google" size={20} color={TEXT_WHITE}></AntDesign>
                <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500', lineHeight: 56 }}>{'  Continue with Google'}</Text>
              </View>}
            </GradientButton>
            {Platform.OS !== 'android' &&
              <GradientButton
              style={{ marginVertical: 8, alignItems: 'center', justifyContent: 'center' }}
              textStyle={{ fontSize: 20, color: TEXT_WHITE }}
              gradientBegin={'rgb(0,0,0)'}
              gradientEnd={'rgb(15,15,15)'}
              disabledGradientBegin={PRIMARY_COLOR_DISABLED}
              disabledGradientEnd={PRIMARY_COLOR_DISABLED}
              gradientDirection="diagonal"
              height={56}
              width={300}
              radius={15}
              impact
              impactStyle='Light'
              disabled={isLoading}
              onPressAction={() => appleLogin()}
              >
                {isLoading ? getLoadingIndicator() : 
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                  <AntDesign name="apple1" size={20} color={TEXT_WHITE}></AntDesign>
                  <Text maxFontSizeMultiplier={1} style={{ fontSize: 20, color: TEXT_WHITE, fontWeight: '500', lineHeight: 56 }}>{'  Continue with Apple'}</Text>
                </View>}
              </GradientButton>
            }
            
          </View>}
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.container, styles.horizontal, styles.darkBackground]}>
      {getLoginScreen()}
    </View>
  );
};

export default LoginContainer;
