import React, {useState, useEffect, createContext, useContext, useCallback, useRef} from "react";
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import config from "./config.js";
import { StatusBar, View, AppState, TouchableWithoutFeedback  } from "react-native";
import { BlurView } from 'expo-blur'
import * as ImagePicker from 'expo-image-picker';
import { DataT, ProfileT } from "./types";
import * as SecureStore from 'expo-secure-store';
import * as SplashScreen from 'expo-splash-screen';
import { ActivityIndicator, Platform  } from 'react-native';
import { vw, vh, vmin, vmax } from 'react-native-expo-viewport-units'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { DarkTheme, NavigationContainer } from "@react-navigation/native";
import { createStackNavigator, CardStyleInterpolators } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";
import { Home, Matches, Messages, Profile, CreateProfileContainer, Conversation, ErrorContainer } from "./screens";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, WHITE, BACKGROUND_COLOR, SECONDARY_RED } from "./assets/styles";
import TabBarIcon from "./components/TabBarIcon";
import * as Location from 'expo-location';
import {AppContextProvider, AppContext, checkLogin, fetchUserProfile, fetchProfiles, fetchMatches, syncMyProfileData} from "./context/app-context";
import { get, concat, isEmpty, filter } from 'lodash';
import LoginContainer from "./screens/LoginContainer";
import * as RootNavigation from './RootNavigation';
import {navigationRef } from './RootNavigation';
import { AntDesign, Entypo, Feather, Foundation, FontAwesome, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons"
import * as Font from 'expo-font'

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

// *** DON'T USE THE FOLLOWING CODE IF YOU DON'T WANT NOTIFICATION TO BE DISPLAYED
// WHILE THE APP IS IN FOREGROUND! ***
// --------------------------------------------------
// Sets the handler function responsible for deciding
// what to do with a notification that is received when the app is in foreground

Notifications.setNotificationHandler({
  handleNotification: async (notification) => {
    const alert = AppState.currentState == 'active' ? false : true //Check if on the actual message page, otherise show alert
    return ({
    shouldShowAlert: alert,
    shouldPlaySound: false,
    shouldSetBadge: false,
  })},
});

function cacheFonts(fonts) {
  Font.loadAsync({
    IcoMoon: require('./icons/icomoon.ttf')
  })
  return fonts.map(font => Font.loadAsync(font));
}


const Container = () => {
  const [state, dispatch] = useContext(AppContext);
  const [appIsReady, setAppIsReady] = useState(false);

  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const unfilteredMatches = get(state, `matches`, [])
  const unreadMatches = filter(unfilteredMatches, (match) => {
    return match.is_liked && match.likes_me && (match?.messages?.length === 0 || get(match, 'messages[0].to_profile_id') !== match?.id)
  })
  const unreadLikes = filter(unfilteredMatches, {is_liked: null, likes_me: true})

  const fetchData = async () => {
    const fontAssets = cacheFonts([AntDesign.font, Entypo.font, Foundation.font, Feather.font, FontAwesome.font, Ionicons.font, MaterialCommunityIcons.font])
    
    await SplashScreen.preventAutoHideAsync();
    const result = await checkLogin()
    if(!result.logged_in) {
      //RootNavigation.navigate('Login') 
      await Promise.all([...fontAssets])
      setAppIsReady(true);
      return
    }
    dispatch({
      type: "RECEIVE_LOGIN",
      payload: result
    })
    const response = await fetchUserProfile(dispatch)
    if(!get(response, 'profile.profile_complete', false)) {
      await Promise.all([...fontAssets])
      setAppIsReady(true);
      return
    }
    await syncMyProfileData(dispatch)
    await fetchProfiles(dispatch)
    await fetchMatches(dispatch)
    await dispatch({
      type: "SET_PROFILE_INDEX",
      payload: 0
    })
    await new Promise(resolve => setTimeout(resolve, 200));
    await Promise.all([...fontAssets])
    setAppIsReady(true);
  }

  const handleAppStateChange = (state) => {
    console.log('appstate', state);
    if(state === 'active') {
      fetchMatches(dispatch)
    }
  }

  useEffect(() => {
    (async () => {
      if(Platform.OS === 'web') {
        document.body.style.backgroundColor = BACKGROUND_COLOR
        document.body.style.overflowY = 'hidden'
        document.body.style.overflowX = 'hidden'
      }
      
      /* let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        console.log('Permission to access location was denied');
        //return;
      }  */
      
      await fetchData()
      //navInitial()
    })();
    {

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        console.log('foreground listen')
        fetchMatches(dispatch)
      });

      // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      responseListener.current = Notifications.addNotificationResponseReceivedListener(notification => {
        console.log('foreground listen click')
        const data = notification?.notification?.request?.content?.data
        fetchMatches(dispatch)
        if(data?.id && data?.type === 'message') RootNavigation.navigate('Conversation', {id: data?.id})
        if(data?.id && data?.type === 'like') RootNavigation.navigate('Matches', {id: data?.id})
      });

      if(Platform.OS === 'web') {
        try {
          const broadcast = new BroadcastChannel('push-channel')
        
          // Listen to the response
          broadcast.onmessage = (event) => {
            console.log("push-channel", event)
            fetchMatches(dispatch)
          }

          const broadcastClick = new BroadcastChannel('push-channel-click')
        
          // Listen to the response
          broadcastClick.onmessage = (event) => {
            console.log("push-channel-click", event)
            const data = event?.data
            fetchMatches(dispatch)
            if(data?.id && data?.type === 'message') RootNavigation.navigate('Conversation', {id: data?.id})
            if(data?.id && data?.type === 'like') RootNavigation.navigate('Matches', {id: data?.id})
          }
        } catch(err) {
          console.log(err)
        }
      }
      

      AppState.addEventListener('change', handleAppStateChange)

      return () => {
        AppState.removeEventListener('change', handleAppStateChange)
        Notifications.removeNotificationSubscription(notificationListener.current);
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, [])

  const initNavigation = () => {
    navInitial()
  }

  const navInitial = () => {
    if(!state.logged_in)
      RootNavigation.navigate('Login') 
    else if (get(state, 'user_profile_error') === true)
      RootNavigation.navigate('UserProfileError')
    else if(get(state, 'user_profile.profile_complete') === false) 
      RootNavigation.navigate('CreateProfile')
    else
      RootNavigation.navigate('Home')  
  }

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }



  

  const forFade = ({ current }) => ({
    cardStyle: {
      opacity: current.progress,
    },
  });
  
  return (
    
    <View style={{ flex: 1, backgroundColor: styles.darkBackground }} onLayout={onLayoutRootView}>
      <NavigationContainer ref={navigationRef} onReady={() => {initNavigation()}} theme={{ colors: { background: BACKGROUND_COLOR, notification: SECONDARY_RED } }}
        documentTitle={{
        formatter: (options, route) => 
        `Especially for Singles | Latter Day Saint Dating`,
       }}>
      <StatusBar
        backgroundColor="black"
        barStyle="light-content"
      />
       <Stack.Navigator initialRouteName={state.logged_in ? 'Home' : 'Login'} style={{backgroundColor: BACKGROUND_COLOR}} screenOptions={{cardStyleInterpolator: forFade, cardStyle: {backgroundColor: 'transparent',
            width: vw(100),
            maxWidth: vh(60),
            alignSelf: 'center',
            height: '100%' }}} > 
        <Stack.Screen name="Login" options={{ headerShown: false, animationEnabled: true }} component={LoginContainer} style={{backgroundColor: BACKGROUND_COLOR}} />
        <Stack.Screen name="CreateProfile" options={{ headerShown: false, animationEnabled: true }} component={CreateProfileContainer} style={{backgroundColor: BACKGROUND_COLOR}} />
        <Stack.Screen name="UserProfileError" options={{ headerShown: false, animationEnabled: true }} component={ErrorContainer} style={{backgroundColor: BACKGROUND_COLOR}} />
        <Stack.Screen name="Conversation" options={{ headerShown: false, animationEnabled: true }} component={Conversation} style={{backgroundColor: BACKGROUND_COLOR}} />
        <Stack.Screen name="Home" options={{  headerShown: false, animationEnabled: true }} style={{backgroundColor: BACKGROUND_COLOR}}>
        {() => (
        <Tab.Navigator
          screenOptions={{
            headerShown: false,
            tabBarShowLabel: false,
            tabBarHideOnKeyboard: true,
            unmountOnBlur: true,
            tabBarStyle: {
              elevation: 0,
              shadowOpacity: 0,
              shadowColor: 'transparent',
              borderColor: 'transparent',
              borderWidth: 0
            }
          }}
        >
          <Tab.Screen
            name="Explore"   
            component={Home}
            style={{backgroundColor: BACKGROUND_COLOR}}
            options={{
              tabBarIcon: ({ focused }) => (
              <TabBarIcon
                  focused={focused}
                  iconName="search"
                  text="Explore"
              />
              ),
            }}
          />

          <Tab.Screen
            name="LikesAndMatches"
            component={Matches}
            style={{backgroundColor: BACKGROUND_COLOR}}
            options={{
              tabBarIcon: ({ focused }) => (
              <TabBarIcon
                  focused={focused}
                  iconName="heart"
                  text="Matches"
              />
              ),
              tabBarBadge: unreadLikes?.length ? unreadLikes?.length : undefined,
            }}
          />

          <Tab.Screen
            name="Chat"
            component={Messages}
            style={{backgroundColor: BACKGROUND_COLOR}}
            options={{
              tabBarIcon: ({ focused }) => (
              <TabBarIcon
                  focused={focused}
                  iconName="chatbubble"
                  text="Chat"
              />
              ),
              tabBarBadge: unreadMatches?.length ? unreadMatches?.length : undefined
            }}
          />

          <Tab.Screen
            name="Profile"
            component={Profile}
            style={{backgroundColor: BACKGROUND_COLOR}}
            options={{
              tabBarIcon: ({ focused }) => (
              <TabBarIcon
                  focused={focused}
                  iconName="person"
                  text="Profile"
              />
              ),
            }}
          />
        </Tab.Navigator>
        )}
        </Stack.Screen>
      </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}

global.eventSource = null

export default Container
