import React, {useState, useEffect, createContext, useContext} from "react";
import config from "./config.js";
import { Platform, StatusBar, View  } from "react-native";
import { DataT, ProfileT } from "./types";
import * as SecureStore from 'expo-secure-store';
import { ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";
import { Home, Matches, Messages, Profile } from "./screens";
import styles, { PRIMARY_COLOR, SECONDARY_COLOR, WHITE, BACKGROUND_COLOR } from "./assets/styles";
import TabBarIcon from "./components/TabBarIcon";
import {AppContextProvider, AppContext, fetchData} from "./context/app-context";
import { get, concat } from 'lodash';
import ProfileModalContext from "./modals/ProfileModalContext";
import ConfirmationModalContext from "./modals/ConfirmationModalContext"
import Toast from 'react-native-toast-message'
import * as Sentry from 'sentry-expo'

import Container from "./Container";

Sentry.init({
  dsn: 'https://9d0edb9031f92877e2907588f853f64b@o1194388.ingest.sentry.io/4505771189141504',
  enableInExpoDevelopment: true,
  debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

const App = () => {

  return (
    <AppContextProvider>
      <ConfirmationModalContext>
        <ProfileModalContext>
          <Container/>
        </ProfileModalContext>
      </ConfirmationModalContext>
      <Toast/>
    </AppContextProvider>
    
  )
}

export default App
