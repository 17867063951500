import { DataT, ProfileT } from "../../types";
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { ActivityIndicator, Platform } from 'react-native';
import config from "../../config.js";
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AppStorage from '../storage'
import * as Location from 'expo-location';
import * as RootNavigation from '../../RootNavigation';
import { get, concat, has, invoke } from 'lodash';
import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import * as Device from 'expo-device';
import { fetchMatches } from "../app-context";

export const fetchProfile = async () => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    //console.log('login token', token)
    const response = await fetch(`${config.API_URL}/profiles/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      }
    });
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources[0]');
      return {
        profile: p
      };
    } else {
      if (response.status == 404) {
        await AppStorage.deleteItemAsync('secure_token')
        const errs = get(json, 'errors');
        console.log('profile does not exist', json)
        //RootNavigation.navigate('Login')
        return {
          error: `${response.status}: ${response.statusText} ${errs}`
        };
      }
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    console.log('error fetching user profile', err)
    return {
      error: err
    }
  }
}

export const reverseGeocode = async (latitude, longitude) => {
  try {
    const response = await fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`, {
      method: 'GET',
    });
    const json = await response.json();
    //console.log('json', json)
    if (response.status == 200) {
      return {
        json
      };
    } else {
      if (response.status == 404) {
        return {
          error: `${response.status}: ${response.statusText}`
        };
      }
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const fetchGeolocation = async () => {
  try {
    const response = await fetch(`http://ip-api.com/json`, {
      method: 'GET',
      headers: {
        'Origin': 'https://ip-api.com',
      }
    });
    const json = await response.json();
    if (response.status == 200) {
      return {
        json
      };
    } else {
      if (response.status == 404) {
        return {
          error: `${response.status}: ${response.statusText}`
        };
      }
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const updateProfileLocationToken = async (callback) => {
  try {
    console.log('updating profile!')
    const profile = {}
    let location = await Location.getLastKnownPositionAsync()
    if(!location) location = await Location.getCurrentPositionAsync()
    const latitude = get(location, 'coords.latitude', 40.466354321340035, )
    const longitude = get(location, 'coords.longitude', -111.90801180994336)
    /* const cool = await Location.reverseGeocodeAsync({latitude: latitude, longitude: longitude})
    console.log('cool: ', cool) */
    if(has(location, 'coords.latitude')) profile.latitude = location?.coords.latitude
    if(has(location, 'coords.longitude')) profile.longitude = location?.coords.longitude
    if(has(location, 'coords.latitude') && has(location, 'coords.longitude')) {
      const geo = await reverseGeocode(location?.coords.latitude, location?.coords.longitude)
      console.log(geo)
      if(has(geo, 'json.city') && has(geo, 'json.principalSubdivision')) {
        profile.city = get(geo, 'json.city')
        profile.state = get(geo, 'json.principalSubdivision')
        profile.country = get(geo, 'json.countryName')
      }
      //console.log(profile)
    }
    const token = await registerForPushNotificationsAsync()
    if(token?.length > 0) {
      console.log('push_token', token)
      profile.push_token = token
    } else {
      //await registerForServerSendEvents(callback)
    }
    
    return patchProfile(profile)
  }  catch (err) {
    return {
      error: err
    }
  }
}

export const queryProfiles = async () => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    let profileJSON = await AsyncStorage.getItem('user_profile')
    var profile: ProfileT = JSON.parse(profileJSON)
    const gender = profile.gender == 'female' ? 'male' : 'female'
    const max_distance = get(profile, 'max_distance', 1000) || 1000
    const max_age = get(profile, 'max_age', 100) || 100
    const min_age = get(profile, 'min_age', 18) || 18
    const location = await Location.getLastKnownPositionAsync()
    const latitude = get(location, 'coords.latitude', 40.466354321340035, )
    const longitude = get(location, 'coords.longitude', -111.90801180994336)
    const response = await fetch(`${config.API_URL}/profiles/query?latitude=${latitude}&longitude=${longitude}&max_distance=${max_distance}&max_age=${max_age}&min_age=${min_age}&gender=${gender}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      }
    })
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources');
      return {
        profiles: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const queryMatches = async () => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    let profileJSON = AsyncStorage ? await AsyncStorage.getItem('user_profile') : ''
    var profile: ProfileT = JSON.parse(profileJSON)
    const gender = profile.gender == 'female' ? 'male' : 'female'
    const max_distance = 500//get(profile, 'max_distance', 100)
    const max_age = 100 //get(profile, 'max_age', 100)
    const min_age = 18 //get(profile, 'min_age', 18)
    const location = await Location.getLastKnownPositionAsync()
    const latitude = get(location, 'coords.latitude', 40.466354321340035, )
    const longitude = get(location, 'coords.longitude', -111.90801180994336)
    const response = await fetch(`${config.API_URL}/profiles/matches?latitude=${latitude}&longitude=${longitude}&max_distance=${max_distance}&max_age=${max_age}&min_age=${min_age}&gender=${gender}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      }
    })
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources');
      return {
        profiles: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const patchProfile = async (profile : any) => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    if(!token) return {
      error: 'no token'
    }
    const response = await fetch(`${config.API_URL}/profiles/profile`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
      body: JSON.stringify(profile)
    });
    
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources[0]');
      return {
        profile: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    console.log('patchProfile', err, profile)
    return {
      error: err
    }
  }
}

export const doUploadPhoto = async (photo : any) => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    const response = await fetch(`${config.API_URL}/profiles/photos`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
      body: JSON.stringify({
        data: photo
      })
    });
    const json = await response.json();
    
    if (response.status == 200) {
      const p = get(json, 'resources[0]');
      return {
        profile: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    console.log('doUploadPhoto', err)
    return {
      error: err
    }
  }
}

export const doRemovePhoto = async (photo : any) => {
  try {
    let token = await AppStorage.getItemAsync('secure_token')
    const response = await fetch(`${config.API_URL}/profiles/photos?filename=${photo}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      }
    });
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources[0]');
      return {
        profile: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}


export const doProfileAction = async (action : string, otherProfileID : string, details? : any) => {
  try {
    let token = await AppStorage.getItemAsync('secure_token');
    const response = await fetch(`${config.API_URL}/profiles/action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
      body: JSON.stringify({
        id: otherProfileID,
        details: details,
        action: action
      })
    });
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources');
      return {};
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const doDeleteProfile = async () => {
  try {
    let token = await AppStorage.getItemAsync('secure_token');
    const response = await fetch(`${config.API_URL}/profiles/profile`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
    });
    const json = await response.json();
    if (response.status == 200) {
      return {};
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

export const doProfileRewind = async () => {
  try {
    let token = await AppStorage.getItemAsync('secure_token');
    const response = await fetch(`${config.API_URL}/profiles/rewind`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
    });
    const json = await response.json();
    if (response.status == 200) {
      return {};
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return { 
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}


export const doMessageProfile = async (message : string, otherProfileID : string) => {
  try {
    let token = await AppStorage.getItemAsync('secure_token');
    const response = await fetch(`${config.API_URL}/profiles/message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-csrf-token': 'esf-app'
      },
      body: JSON.stringify({
        id: otherProfileID,
        message: message
      })
    });
    const json = await response.json();
    if (response.status == 200) {
      const p = get(json, 'resources[0]');
      return {
        profile: p
      };
    } else {
      console.log(response.status, response.statusText, json)
      const errs = get(json, 'errors');
      return {
        error: `${response.status}: ${response.statusText} ${errs}`
      };
    }
    
  } catch (err) {
    return {
      error: err
    }
  }
}

const firebaseApp = initializeApp(config.FIREBASE_CONFIG);



export async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  console.log('registerForPushNotificationsAsync 1')
  if(Platform.OS === 'web') {
    try {
      console.log('registering for web notifications...')
  
      const messaging = getMessaging(firebaseApp);
      token = await getToken(messaging, { vapidKey: config.VAPID_PUBLIC_KEY })
      //console.log('FCM token', token)
      onMessage(messaging, (payload) => {
        //console.log('foreground msg', payload)
        //fetchMatches()
        try {
          const broadcast = new BroadcastChannel('push-channel');
          broadcast.postMessage(payload?.data);
        } catch(err) {
          console.error(err)
        }
      })
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });
      } else {
        console.log('notifications not supported in browser')
      }
    } catch (err) {
      return {
        error: err
      }
    }
  } else if (Device.isDevice) {
    console.log('i am a device')
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    console.log('yoooo')
    token = (await Notifications.getDevicePushTokenAsync()).data;
    console.log(token);
  } else {
    alert('Must use physical device for Push Notifications');
  }
  console.log('registerForPushNotificationsAsync 2')
  
  

  return token;
}
